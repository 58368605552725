import React from "react";
import packageJson from '../package.json';

import { User as FirebaseUser } from "firebase/auth";
import {
  Authenticator,
  buildCollection,
  buildSchema,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";

const firebaseConfig = {
  apiKey: "AIzaSyDyyp44adaFiy5Ti8EIhaQFWBZAJMrYuKE",
  authDomain: "outcomely.firebaseapp.com",
  databaseURL: "https://outcomely.firebaseio.com",
  projectId: "outcomely",
  storageBucket: "outcomely.appspot.com",
  messagingSenderId: "47395199078",
  appId: "1:47395199078:web:c8ad6d356e5a502dc235a7",
  measurementId: "G-7N8ETQL91R",
};

type Answer = {
  title: string;
  score: number;
}

type Question = {
  title: string;
  instruction: string;
  group: number;
  order: number;
  extra_info_title?: string;
  type: string;
  answers: Answer[];
  min: number;
  max: number;
  distance: number;
  time: number;
};

const questionSchema = buildSchema<Question>({
  name: "Question",
  properties: {
    title: {
      title: "Title",
      validation: { required: true },
      dataType: "string"
    },
    instruction: {
      title: "Instruction",
      validation: { required: true },
      dataType: "string",
      config: {
        multiline: true
      }
    },
    group: {
      title: "Group",
      validation: { required: true, min: 0 },
      dataType: "number"
    },
    order: {
      title: "Order",
      validation: { required: true, min: 0 },
      dataType: "number"
    },
    extra_info_title: {
      title: "Extra Info Title",
      dataType: "string",
    },
    type: {
      dataType: "string",
      title: "Question Type",
      validation: {
        required: true
      },
      config: {
        enumValues: {
          "multiplechoice": "Multiple Choice",
          "scale": "Scale",
          "fixed_distance": "Fixed Distance",
          "fixed_time": "Fixed Time"
        }
      }
    },
    answers: ({ values }) => ({
      dataType: "array",
      title: "Answers",
      disabled: values.type !== "multiplechoice" && {
        clearOnDisabled: true,
        hidden: true
      },
      of: {
        dataType: "map",
        properties: {
          title: {
            title: "Title",
            dataType: "string",
            validation: { required: true }
          },
          score: {
            title: "Score",
            dataType: "number",
            validation: { required: true }
          },
        }
      }
    }),
    min: ({ values }) => ({
      title: "Min",
      dataType: "number",
      validation: { 
        required: values.type === "scale", 
        min: 0 
      },
      disabled: values.type !== "scale" && {
        clearOnDisabled: true,
        hidden: true
      },
    }),
    max: ({ values }) => ({
      title: "Max",
      dataType: "number",
      validation: { 
        required: values.type === "scale", 
        min: values.min,
      },
      disabled: values.type !== "scale" && {
        clearOnDisabled: true,
        hidden: true
      },
    }),
    distance: ({ values }) => ({
      title: "Distance",
      dataType: "number",
      validation: { 
        required: values.type === "fixed_distance", 
        min: 0 
      },
      disabled: values.type !== "fixed_distance" && {
        clearOnDisabled: true,
        hidden: true
      },
    }),
    time: ({ values }) => ({
      title: "Time",
      dataType: "number",
      validation: { 
        required: values.type === "fixed_time", 
        min: 0 
      },
      disabled: values.type !== "fixed_time" && {
        clearOnDisabled: true,
        hidden: true
      },
    }),
  }
});

type Measure = {
  name: string;
  objective: string;
  interpretation: string,
  duration: number;
  enabled: boolean;
  equipment: string[];
  categories: string[];
  walking_aid?: boolean;
  rule_set: {
    group_rule: string;
    measure_rule: string;
    template: string;
  };
};

const measureSchema = buildSchema<Measure>({
  name: "Measures",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    objective: {
      title: "Objective",
      validation: {
        required: true,
      },
      dataType: "string",
      config: {
        multiline: true
      }
    },
    interpretation: {
      title: "Interpretation",
      validation: { required: true },
      dataType: "string",
      config: {
        multiline: true
      }
    },
    duration: {
      title: "Duration (seconds)",
      validation: {
        required: true,
        requiredMessage: "Duration must be more than 60 seconds",
        min: 60,
      },
      dataType: "number",
    },
    enabled: {
      title: "Enabled",
      dataType: "boolean",
    },
    equipment: {
      title: "Equipment",
      dataType: "array",
      of: {
        dataType: "string",
      },
    },
    categories: {
      title: "Categories",
      dataType: "array",
      of: {
        dataType: "string",
      },
    },
    walking_aid: {
      title: "Walking Aid?",
      dataType: "boolean"
    },
    rule_set: {
      title: "Rule Set",
      dataType: "map",
      properties: {
        group_rule: {
          title: "Group Rule",
          dataType: "string",
          config: {
            enumValues: {
              "none": "None",
              "sum": "Sum",
              "percent": "Percent",
              "group_0": "Group 0",
              "group_1": "Group 1",
              "group_2": "Group 2",
            }
          }
        },
        measure_rule: {
          title: "Measure Rule",
          dataType: "string",
          config: {
            enumValues: {
              "none": "None",
              "sum": "Sum",
              "percent": "Percent",
              "group_0": "Group 0",
              "group_1": "Group 1",
              "group_2": "Group 2",
            }
          }
        },
        template: {
          title: "Template",
          dataType: "string"
        }
      }
    }
  },
});

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    return {
      collections: [
        buildCollection({
          path: "measures",
          schema: measureSchema,
          name: "Measures",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            delete: authController.extra.roles.includes("admin"),
          }),
          subcollections: [
            buildCollection({
              name: "Questions",
              path: "questions",
              schema: questionSchema
            })
          ]
        }),
      ],
    };
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
  }) => {
    // Temp, all users are admin for now
    const sampleUserData = await Promise.resolve({
      roles: ["admin"],
    });
    authController.setExtra(sampleUserData);

    
    return true;
  };

  console.log("[Version] " + packageJson.version);

  return (
    <FirebaseCMSApp
      name={"Outcomely Admin"}
      authentication={myAuthenticator}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
    />
  );
}
